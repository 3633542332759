import './libs/focus-visible.min.js';
import { Fancybox } from "@fancyapps/ui";
import { canUseWebP as webp } from './components/webp.js';
import { header } from './components/header.js';
import { sliders } from './components/sliders.js';

window.addEventListener('DOMContentLoaded', () => {
  header.init();
  sliders.init();
  webp.init();
});
