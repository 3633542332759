import Swiper from '../libs/swiper.min.js';

export const sliders = {

  node: {
    revSlider: {
      sld: document.querySelector('.js-revSlider'),
    },
    sertSlider: {
      sld: document.querySelector('.js-sertSlider'),
    },
  },

  revSlider: () => {
    if (sliders.node.revSlider.sld) {


      let slider = new Swiper('.js-revSlider', {
        loop: true,
        slidesPerView: 2,
        autoHeight: true,
        navigation: {
          nextEl: '.js-revSliderNext',
          prevEl: '.js-revSliderPrev',
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 35,
          },
          768: {
            slidesPerView: 2,
          },
          1199: {
            spaceBetween: 19,
          }
        }
      });

    }
  },

  sertSlider: () => {
    if (sliders.node.sertSlider.sld) {


      let slider = new Swiper('.js-sertSlider', {
        loop: true,
        slidesPerView: 5,
        navigation: {
          nextEl: '.js-sertSliderNext',
          prevEl: '.js-sertSliderPrev',
        },
        breakpoints: {
          0: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          480: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 4,
          },
          992: {
            spaceBetween: 26,
            slidesPerView: 5,
          }
        }
      });

    }
  },

  run: () => {
    sliders.revSlider();
    sliders.sertSlider();
  },

  init: () => {
    sliders.run();
  }
}