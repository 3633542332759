export const header = {
  node: {
    elem: document.querySelector('header') || document.querySelector('.header'),
    mobileToggle: document.querySelectorAll('.js-mobileMenu'),
    arrowUp: document.querySelector('.js-back')
  },

  mobileToggle: () => {
    if( header.node.mobileToggle ) {
      header.node.mobileToggle.forEach( el => {
        el.addEventListener( 'click', function(e) {
          e.preventDefault();
          document.body.classList.toggle('open-menu')
        } );
      } );
    }
  },

  arrowUp: () => {

    if( header.node.arrowUp ) {

      const checkWindowPos = () => {
        if (window.scrollY > 800 ) {
          header.node.arrowUp.classList.add('show');
        }else {
          header.node.arrowUp.classList.remove('show');
        }
      }

      window.addEventListener('scroll', checkWindowPos);

      checkWindowPos();
      
      header.node.arrowUp.addEventListener('click', function() {
        $("html, body").animate({
          scrollTop: 0
        }, 300);
        return false;
      })
    }
  },

  run: () => {
    header.mobileToggle();
    header.arrowUp();
  },

  init: () => {
    header.run();
  }
}